import React, { useEffect, useState } from "react";
import { Line, Bar, Scatter } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, PointElement, Tooltip, Legend, Title } from "chart.js";
import SocialMediaAnalysis from "./chartAnalyzer/chartAnalyzer";
import { GetCompanyId } from "../../GatherUserInformationDB/GatherDatabaseUser";
import { hostLink } from "../../DatabaseCommunication/GetDataFromUser";
import LoadingModule from "../../loading/loading";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, LineElement, BarElement, PointElement, Tooltip, Legend, Title);

const ChartModule = ({ chartTitle, dataSets, xField, yFields, height = 500, hasPred = true }) => {
    const [chartType, setChartType] = useState("line"); // State for chart type
    const [chartData, setChartData] = useState(null); // Chart.js-compatible data
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDataPoint, setSelectedDataPoint] = useState(null);
    const [boxPosition, setBoxPosition] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [isSaving, setIsSaving] = useState(false);


    
    useEffect(() => {
        const fetchData = async () => 
            {
                if (!dataSets || dataSets.length === 0) return;
            
                try 
                {
                    // Fetch company ID
                    let compIdResponse = await GetCompanyId();
                    let compId = await compIdResponse.json();
                    compId = compId["id"];
            
                    // Fetch prediction data
                    let predResponse = await fetch(`${hostLink}/GetPredictionData`, 
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ id: compId }),
                    });
            
                    let pred = await predResponse.json();
                    pred = pred["data"].map(JSON.parse);
                    pred = pred[0];
            
                    let combinedData = mergeDataSets(dataSets, xField);
                    const predField = new Set();
            
                    // Récupération des dates existantes
                    const existingDates = new Set(combinedData.map((dataRow) => dataRow["date"]));
            
                    // Mise à jour des données combinées
                    combinedData = combinedData.map((dataRow) => {
                        const rowWithPrediction = { ...dataRow };
            
                        yFields.forEach((column) => {
                            const predictionField = `${column}_prediction`;
                            predField.add(predictionField); // Ajoute le champ au Set
            
                            if (pred[dataRow["date"]]) {
                                // Récupérer la valeur de prédiction ou 0 si inexistante
                                rowWithPrediction[predictionField] =
                                    pred[dataRow["date"]][predictionField] ?? 0;
                            } else {
                                rowWithPrediction[predictionField] = 0;
                            }
                        });
            
                        return rowWithPrediction;
                    });
            
                    // Ajout des lignes restantes de prédictions
                    Object.keys(pred).forEach((date) => 
                    {
                        if (!existingDates.has(date)) 
                        {
                            const newRow = { date };
            
                            yFields.forEach((column) => 
                            {
                                const predictionField = `${column}_prediction`;
                                predField.add(predictionField);
            
                                // Initialiser les champs
                                newRow[predictionField] = pred[date][predictionField] ?? 0;
                                newRow[column] = 0; // Valeurs non prédiction à 0
                            });
            
                            combinedData.push(newRow);
                        }
                    });
            
                    // Fusionne les nouveaux champs avec les anciens sans doublons
                    yFields = Array.from(new Set([...yFields, ...predField]));
            
                    console.log("Combined Data:", combinedData);
            
                    // Prepare data for Chart.js
                    const datasets = yFields.map((field) => 
                    {
                        const isPrediction = field.endsWith("_prediction");
            
                        return {
                            label: isPrediction ? `${field} (Prediction)` : field,
                            data: combinedData.map((item) => item[field] || 0),
                            borderColor: isPrediction ? "red" : getRandomColor(),
                            borderDash: isPrediction ? [5, 5] : [],
                            backgroundColor: isPrediction ? "rgba(255, 0, 0, 0.3)" : getRandomColor(0.3),
                            tension: 0.4, // Smooth lines
                            fill: false, // No fill for lines
                            type: chartType === "area" ? "line" : chartType,
                        };
                    });
            
                    setChartData({
                        labels: combinedData.map((item) => item[xField]),
                        datasets: datasets,
                    });
            
                } 
                catch (error) 
                {
                    console.error("Error fetching or processing data:", error);
                }
            };
            
            

        fetchData();
    }, [dataSets, chartType, xField, yFields, hasPred]);

    const getRandomColor = (alpha = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const mergeDataSets = (dataSets, xField) => {
        let mergedData = [];
        dataSets.forEach((dataSet) => {
            dataSet.forEach((item) => {
                let existingItem = mergedData.find((data) => data[xField] === item[xField]);
                if (existingItem) {
                    Object.assign(existingItem, item);
                } else {
                    mergedData.push({ ...item });
                }
            });
        });
        return mergedData;
    };


    const handlePointClick = (event, elements) => 
        {
            if (elements.length > 0) 
            {
                const element = elements[0];
                const datasetIndex = element.datasetIndex;
                const index = element.index;
        
                const dataset = chartData.datasets[datasetIndex];
                const dataPoint = {
                    label: dataset.label,
                    value: dataset.data[index],
                    index,
                    datasetIndex,
                };
        
                if (dataset.label.endsWith("(Prediction)")) 
                {
                    // Récupérer la position de la souris via event.native
                    const mouseX = event.native?.clientX || 0;
                    const mouseY = event.native?.clientY || 0;
        
                    // Mettre à jour l'état avec les coordonnées et le point sélectionné
                    setMousePosition({ x: mouseX, y: mouseY });
                    setSelectedDataPoint(dataPoint);
                }
            }
        };

        const handleValueChange = (e) => 
            {
                const newValue = parseFloat(e.target.value);
                if (!isNaN(newValue) && selectedDataPoint) 
                {
                    // Met à jour le dataset
                    const updatedData = [...chartData.datasets];
                    updatedData[selectedDataPoint.datasetIndex].data[selectedDataPoint.index] = newValue;
            
                    setChartData({ ...chartData, datasets: updatedData });
            
                    // Met à jour selectedDataPoint pour refléter la nouvelle valeur
                    setSelectedDataPoint({
                        ...selectedDataPoint,
                        value: newValue,
                    });
                }
            };
            

    const renderChart = () => {
        if (!chartData) return null;

        const options = {
            responsive: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => `${context.dataset.label}: ${context.raw}`,
                    },
                },
            },
            onClick: (event, elements) => handlePointClick(event, elements),
        };

        switch (chartType) {
            case "line":
                return <Line data={chartData} options={options} />;
            case "bar":
                return <Bar data={chartData} options={options} />;
            default:
                return <Scatter data={chartData} options={options} />;
        }
    };
    const CalculateNextMonth = (lastMonth) => 
        {
            const [year, month] = lastMonth.split("-").map(Number); 
            let newMonth = month + 1;
            let newYear = year;
        
            if (newMonth > 12) 
            {
                newMonth = 1;
                newYear += 1;
            }
            return `${newYear}-${String(newMonth).padStart(2, "0")}`;
        };
        
    const AddNewMonth = () => 
        {
            const updatedLabels = [...chartData.labels];
        
            const lastMonth = updatedLabels[updatedLabels.length - 1];
            const newMonth = CalculateNextMonth(lastMonth); 
            updatedLabels.push(newMonth);
        
            const updatedDatasets = chartData.datasets.map(dataset => 
            {
                return {
                    ...dataset,
                    data: [...dataset.data, 0],
                };
            });
        
            setChartData({
                ...chartData,
                labels: updatedLabels,
                datasets: updatedDatasets,
            });
        };
        

    const saveData = async() =>
    {
        setIsSaving(true);

        let dataForDb = [];

        chartData["labels"].forEach((date, index) => {
            let newLine = {};
            newLine["date"] = date

            chartData["datasets"].forEach(data => {
                let label = data["label"];

                if (label.endsWith("(Prediction)"))
                {
                    
                    label = label.slice(0, -" (Prediction)".length);
                }

                newLine[label] = data["data"][index]
            });
            dataForDb.push(newLine);
        });

        let compId = await GetCompanyId();
        compId = await compId.json();
        compId = compId["id"];
        const fetchResult = await fetch(hostLink + "/UpdateCompanyNetworkDatabase", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ allNewRow: dataForDb, id : compId }),
        })
        setIsSaving(false);
    }

    return (
        <div style={{ width: "100%" }}>
        {isSaving && <LoadingModule/>}
            <hr />
            <div className="tableSwitcher">
                <label htmlFor="chartType" style={{ marginRight: "10px" }}>
                    Select Chart Type:
                </label>
                <select
                    id="chartType"
                    value={chartType}
                    onChange={(e) => setChartType(e.target.value)}
                    style={{ padding: "5px", fontSize: "14px" }}
                >
                    <option value="line">Line</option>
                    <option value="bar">Bar</option>
                    <option value="area">Area</option>
                </select>
            </div>
            <div style={{ height: `100%`, width: "100%" }}>
                {chartData ? renderChart() : <p>Loading chart...</p>}
            <div className="tableSwitcher">
                <button id="addEmptyRow" onClick={() => AddNewMonth()}>Ajouter un nouveau mois</button>
                <button id="saveData" onClick={() => saveData()}>Sauvegarder le graphique</button>
                <SocialMediaAnalysis data={dataSets[0]} isOpen={isOpen} setIsOpen={setIsOpen} />
                <button onClick={() => setIsOpen(true)}>Ouvrir l'analyse détaillé</button>
            </div>
            </div>

            {selectedDataPoint && (
    <div
        style={{
            position: "fixed",
            top: 1, // Décalage pour éviter de couvrir le point
            right: 1,
            backgroundColor: "white",
            padding: "10px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            zIndex: 1000,
        }}
    >
        <div className="button" onClick={() => {setSelectedDataPoint(null)}}>fermer</div>
        <p>Modify Prediction Data for {selectedDataPoint.label}:</p>
        <input
            type="number"
            value={selectedDataPoint.value}
            onChange={handleValueChange}
            style={{ padding: "5px", fontSize: "14px" }}
        />
    </div>
)}


        </div>
    );
};

export default ChartModule;
