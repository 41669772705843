import React, { useEffect, useState } from "react";
import '../CircularGraphHours/CircularGraphHours.css';
import GeneraleDataTable from "./GeneralInformationTable";
import MenInformationTable from "./MenInformationTable";
import WomenInformationTable from "./WomenInformationTable";
import CityInformationTable from "./CityTable";
import CountryInformationTable from "./CountryTable";
import ProductDataTable from "./ProductInformationTable";
import LoadingSpinner from "../../../loading/LoadingSpinner";
import { GetUserCompanyData } from "../../../GatherUserInformationDB/GatherDatabaseUser";
import GenderDistributionChart from "./PieChartDistribution";  
import BarChart from "./HeatMapWorld";
import LoadingModule from "../../../loading/loading";

const genderMap = {
    _F: "femme",
    _M: "homme",
    _U: "inconnu"
};

const transformDemographicData = async(data) =>
    {
        return Object.entries(data).reduce((acc, [key, value]) =>
        {
            // Extraire la tranche d'âge et le genre
            const [age, genderSuffix] = key.split("_");
            const gender = genderMap[`_${genderSuffix}`] || "inconnu";
            // Renommer la clé
            const newKey = `${gender} ${age}`;
            acc[newKey] = value;
            return acc;
        }, {});
    };

const OvertimeAnalyseTable = () => 
{
    const [activeTable, setActiveTable] = useState('general'); // State to track the active table
    const [companyData, setCompanyData] = useState(null); // State to store the fetched data
    const [isFinishLoad, setIsFinishLoad] = useState(false); // State to store the fetched data
    const [lastData, setLastDate] = useState("");
    const [calculatedDatas, setCalculatedData] = useState({});
    const [cannotWork, setWork] = useState(false);

    const GetCompData = async () => {
        const compData = await GetUserCompanyData();
        console.log(compData);
        if(compData[0])
        {
        const keys = Object.keys(compData[0]).sort((a, b) => new Date(b) - new Date(a));
        const mostRecentDate = keys[0]; 
        setLastDate(mostRecentDate);
        setCompanyData(compData); 
        }
        else{
            setWork(true);
        }


    }
    
        useEffect(() => 
            {
                GetCompData();
            }, []); 
            useEffect(() => {
                const calculateData = async () => {
                    if (cannotWork)
                    {
                        return;
                    }
                    if (companyData && lastData && companyData[0][lastData]) {
                        const dictCalc = {};
            
                        // Calculs des engagements
                        dictCalc["engagement"] = (companyData[0][lastData]["instagram_likes"] || 0) 
                            + (companyData[0][lastData]["instagram_comments"] || 0)
                            + (companyData[0][lastData]["instagram_shares"] || 0);
            
                        // Calcul des pourcentages
                        const reach = companyData[0][lastData]["instagram_reach"] || 1; 
                        dictCalc["engagement_percent"] = ((dictCalc["engagement"] / reach) * 100).toFixed(2);
                        dictCalc["couv_org"] = ((reach / companyData[0][lastData]["instagram_impressions"]) * 100).toFixed(2);
            
                        // Conversions
                        dictCalc["conversions"] = 
                            (companyData[0][lastData]["instagram_website_clicks"] || 0) 
                            + (companyData[0][lastData]["instagram_profile_links_taps"] || 0);
            
                        // Nombre de pays et villes
                        dictCalc["amount_of_country"] = Object.keys(companyData[0]["demographic_data"]["country"]).length;
                        dictCalc["amount_of_city"] = Object.keys(companyData[0]["demographic_data"]["city"]).length;
            
                        // Répartition par genre
                        dictCalc["amount_of_men"] = Object.entries(companyData[0]["demographic_data"]["age_by_gender"])
                            .filter(([key]) => key.includes("M"))
                            .reduce((total, [, value]) => total + value, 0);
                            
                        dictCalc["amount_of_women"] = Object.entries(companyData[0]["demographic_data"]["age_by_gender"])
                            .filter(([key]) => key.includes("F"))
                            .reduce((total, [, value]) => total + value, 0);
                            
                        dictCalc["amount_of_unknown"] = Object.entries(companyData[0]["demographic_data"]["age_by_gender"])
                            .filter(([key]) => key.includes("U"))
                            .reduce((total, [, value]) => total + value, 0);
            
                        // Transformation des données démographiques
                        dictCalc["age_by_gender"] = await transformDemographicData(companyData[0]["demographic_data"]["age_by_gender"]);
                        console.log(dictCalc);
            
                        setCalculatedData(dictCalc);
                        console.log("itrs fnish buddy !");
                        setIsFinishLoad(true);
                    }
                };
            
                calculateData();
            }, [companyData, lastData]);
            
        

    const renderTable = () => {
        switch (activeTable) {
            case 'general':
                return <GeneraleDataTable />;
            case 'men':
                return <MenInformationTable />;
            case 'women':
                return <WomenInformationTable />;
            case 'city':
                return <CityInformationTable />;
            case 'country':
                return <CountryInformationTable />;               
            case 'product':
                return <ProductDataTable />;
            default:
                return <GeneraleDataTable />;
        }
    };

    if(cannotWork)
    {
        return (<div></div>);
    }

    if(!isFinishLoad)
    {
        return(
        <>
        </>);
    }
    return (
        <div className="containerNetwork">
            <h1>Quelle est votre évolution au fil du temps sur Instagram ?</h1>
            <hr />
            <h3>Voici toutes vos données de votre compte Instagram.</h3>
            <div>
            <h1>Analyse global</h1>
            { companyData && (  
                    <div className="youtubeChannelInsights grid">   

<div className="insight-card">
    <i className="fas fa-globe icon"></i>
            <p>Nombre de pays atteint :</p>
        <span className="highlight">    
            {calculatedDatas["amount_of_country"]} pays sur 195 !
        </span>
        <progress value={(parseFloat(calculatedDatas["amount_of_country"]) / 195.0)} max="1"></progress>
        <hr/>
    <i className="fas fa-map-marker-alt icon"></i>
        <p>Nombre de ville atteint :</p>
        <span className="highlight">
            {calculatedDatas["amount_of_city"]}
        </span>
    </div>

<div className="insight-card">
        <BarChart title="Follower par pays" labels={Object.keys(companyData[0]["demographic_data"]["country"])} data={Object.values(companyData[0]["demographic_data"]["country"])}/>
    </div>

                    </div>
                )
            }

            <h1>Analyse du dernier mois</h1>
            { companyData && (  
                    <div className="youtubeChannelInsights grid">
                        <div className="insight-card">
                        <i className="fas fa-clock icon"></i>
                                <p>nombre unique d'utilisateur atteint</p>
                            <span className="highlight">
                                {companyData[0][lastData]["instagram_reach"]} utilisateurs
                            </span>
                                <p>engagements</p>
                            <span className="highlight">
                                {calculatedDatas["engagement"]} engagements
                            </span>
                                <p>Taux d'engagement</p>
                            <span className="highlight">
                                {calculatedDatas["engagement_percent"]} %
                            </span>
                            <progress value={calculatedDatas["engagement_percent"]} max="100"></progress>
                        </div>



                        <div className="insight-card">
                        <i className="fas fa-clock icon"></i>
                                <p>Nombre d'utilisateurs atteint par impressions</p>
                            <span className="highlight">
                                {calculatedDatas["couv_org"]} % de viralité (couverture organique)
                            </span>
                                <p>Un rapport élevé pourrait indiquer une bonne couverture organique (recommendation par algorythme).</p>
                        </div>
                                    
                         
                                    
                        <div className="insight-card">
                        <i className="fas fa-clock icon"></i>
                                <p>Conversions</p>
                            <span className="highlight">
                                {calculatedDatas["conversions"]} conversions
                            </span>
                        </div>
                        <div className="insight-card">
                        <i className="fas fa-clock icon"></i>
                                <p>Nombre d'individu</p>
                            <span className="highlight">
                               {calculatedDatas["amount_of_men"]} femmes
                            </span>
                            <span className="highlight">
                                {calculatedDatas["amount_of_women"]} hommes
                            </span>
                            <span className="highlight">
                                {calculatedDatas["amount_of_unknown"]} de genre inconnu
                            </span>
                            <GenderDistributionChart data={{labels: ['Men', 'Women', 'Unknown'], datasets: [{data: [parseInt(calculatedDatas["amount_of_men"]),     parseInt(calculatedDatas["amount_of_women"]),     parseInt(calculatedDatas["amount_of_unknown"])],backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FFCE56']    }]}}/>
                        </div>

                        

                        <BarChart title="Genre par âge" labels={Object.keys(calculatedDatas["age_by_gender"])} data={Object.values(calculatedDatas["age_by_gender"])} type="bar"/>


                    </div>
                )
            }

            </div>
            {/* Boutons pour passer d'une table à l'autre */}
            {/* <div className="tableSwitcher">
                <button
                    className={activeTable === 'general' ? 'active' : ''}
                    onClick={() => setActiveTable('general')}
                >
                    Données générales
                </button>
                <button
                    className={activeTable === 'men' ? 'active' : ''}
                    onClick={() => setActiveTable('men')}
                >
                    Données hommes
                </button>
                <button
                    className={activeTable === 'women' ? 'active' : ''}
                    onClick={() => setActiveTable('women')}
                >
                    Données femmes
                </button>
                <button
                    className={activeTable === 'city' ? 'active' : ''}
                    onClick={() => setActiveTable('city')}
                >
                    Données par ville
                </button>
                <button
                    className={activeTable === 'country' ? 'active' : ''}
                    onClick={() => setActiveTable('country')}
                >
                    Données par pays
                </button>
                <button
                    className={activeTable === 'product' ? 'active' : ''}
                    onClick={() => setActiveTable('product')}
                >
                    Données par produit
                </button>
            </div> */}

            {/* Afficher la table active en fonction du bouton sélectionné */}
            <div className="tableContent">
                {renderTable()}
            </div>
        </div>
    );
};

export default OvertimeAnalyseTable;



export function GenerateCellStyle() 
{
    return (params) => 
    {
        const currentValue = parseFloat(params.value);
        const rowIndex = params.node.rowIndex;

        // If it's the first row, no previous value to compare
        if (rowIndex === 0 || isNaN(currentValue)) 
        {
            return { backgroundColor: 'white' };
        }

        // Get previous row's value
        const previousRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
        const previousValue = previousRow ? parseFloat(previousRow.data[params.colDef.field]) : null;

        // Ensure valid previous value
        if (previousValue === null || isNaN(previousValue)) 
        {
            return { fontWeight: 'normal' }; // No previous value to compare
        }

        // Apply conditional coloring based on comparison
        return {
            color: currentValue > previousValue ? 'green' : (currentValue < previousValue ? 'red' : 'black'),
            fontWeight: currentValue > previousValue ? 'bold' : (currentValue < previousValue ? 'bold' : 'normal'),
        };
    };
}




