import React, { useEffect } from 'react';
import EchoWaveLandingApp from './EchowaveLandingPage/EchoWaveLandingPage';
import './App.css';
import {HashRouter, BrowserRouter, Routes, Route} from "react-router-dom";
import AccountPage from './AccountManager/AccountLandingPage';
import GetStartedEchoWave from './EchowaveLandingPage/GetStartedEchoWave/GetStartedEchoWave';
import PrivacyPolicyPage from './contract/PrivacyPolicy/PrivacyPolicy';
import TermOfService from './contract/term of service/term of service';
import CompanyFeed from './EchowaveAppContent/CompanyFeed';
import WelcomePage from './EchowaveAppContent/Connexion/ConnectionPage';
import AccountPageManager from './EchowaveAppContent/ProfilManager/profil';
import NetworkPage from './EchowaveAppContent/Network/Network';
import WeeklyAnalysis from './EchowaveAppContent/Network/WeeklyAnalysis/WeeklyAnalysis';
import LearningIndicators from './EchowaveAppContent/Network/Learn/indicators/LearnIndators';
import Cookies from "js-cookie";
import WelcomePagePulse from './SuitePulse/LandingPage';
import { useNavigate } from 'react-router-dom';


function App() {
    useEffect(() => {
        document.title = "Pulse software"; 

        const params = new URLSearchParams(window.location.hash.substring(1));
        for (const [key, value] of params.entries()) {
            console.log(`${key}: ${value}`);
        }
        const accessTokenInstagram = params.get('/?accessTokenInstagram');
        const userIdInstagram = params.get('instagram_id');

        console.log("token instagram : " + accessTokenInstagram);
        console.log("id instagram : " + userIdInstagram);

        if (accessTokenInstagram && userIdInstagram)
        {
            Cookies.set('accessTokenInstagram', accessTokenInstagram, { expires: 30 });
            Cookies.set('userIdInstagram', userIdInstagram, { expires: 30 });
            window.location.href = "/#/echowave/Network";
        }

    }, []);

    return (
        <div className="App">
            <HashRouter>
                <div>
                   <Routes>
                        <Route path='/' element={<EchoWaveLandingApp/>}/>
                        <Route path='/Account' element={<AccountPage/>}/>
                        <Route path='/getaccessechowave' element={<GetStartedEchoWave/>}/>
                        <Route path='/privacypolicy' element={<PrivacyPolicyPage/>}/>
                        <Route path='/termsrv' element={<TermOfService/>}/>

                        <Route path='/PulseApps' element={<WelcomePagePulse/>}/>
                        <Route path='/echowave' element={<WelcomePage/>}/>
                        <Route path='/echowave/CompanyFeed' element={<CompanyFeed/>}/>
                        <Route path='/echowave/profil' element={<AccountPageManager/>}/>
                        <Route path='/echowave/Network' element={<NetworkPage/>}/>
                        <Route path='/echowave/learn/indicators' element={<LearningIndicators/>}/>
                        <Route path='/echowave/Network/weekAnalysis' element={<WeeklyAnalysis/>}/>
                    </Routes>
                </div>
            </HashRouter>
        </div>
    );
}

export default App;
