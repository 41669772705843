import React, { useState, useEffect } from 'react';
import { Chart } from 'chart.js/auto';

const YearAnalyzer = ({ companyData }) => {
  const [isPageOpen, setIsPageOpen] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [chartData, setChartData] = useState(null);

  const calculateStatistics = (data) => {
    const sortedData = [...data].sort((a, b) => a - b);
    const sum = data.reduce((acc, val) => acc + val, 0);
    const avg = sum / data.length;
    const min = Math.min(...data);
    const max = Math.max(...data);
    const median = data.length % 2 === 0
      ? (sortedData[data.length / 2 - 1] + sortedData[data.length / 2]) / 2
      : sortedData[Math.floor(data.length / 2)];

    const variance = data.reduce((acc, val) => acc + Math.pow(val - avg, 2), 0) / data.length;
    const stdDev = Math.sqrt(variance);

    return { avg, min, max, median, stdDev };
  };

  const calculateAverageEvolution = (values) => {
    let totalEvolution = 0;
    let count = 0;
    for (let i = 1; i < values.length; i++) {
      const prevValue = values[i - 1] || 1;
      const currentValue = values[i] || 1;
      totalEvolution += ((currentValue - prevValue) / prevValue) * 100;
      count++;
    }
    return count > 0 ? totalEvolution / count : 0;
  };

  const isValidDateKey = (key) => {
    return /^\d{4}-\d{2}$/.test(key);
  };

  const handleButtonClick = () => {
    setIsPageOpen(true);

    let sortedMonths = Object.keys(companyData)
      .filter(isValidDateKey)
      .sort();

    if (sortedMonths.length === 0) {
      console.error("No valid date keys found in the data.");
      return;
    }

    const firstMonth = sortedMonths[0];
    const lastMonth = sortedMonths[sortedMonths.length - 1];

    const updatedStatistics = [];
    const chartLabels = sortedMonths;
    const chartDatasets = [];

    for (const dataKey of Object.keys(companyData[firstMonth])) {
      const monthlyValues = sortedMonths.map((month) => companyData[month]?.[dataKey] || 0);
      const stats = calculateStatistics(monthlyValues);

      stats.totalEvolution = ((monthlyValues[monthlyValues.length - 1] - monthlyValues[0]) / (monthlyValues[0] || 1)) * 100;
      stats.avgEvolution = calculateAverageEvolution(monthlyValues);

      updatedStatistics.push({ key: dataKey, ...stats });

      chartDatasets.push({
        label: dataKey,
        data: monthlyValues,
        borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
        fill: false,
      });
    }

    setStatistics(updatedStatistics);
    setChartData({ labels: chartLabels, datasets: chartDatasets });
  };

  useEffect(() => {
    if (chartData) {
      const ctx = document.getElementById('yearAnalyzerChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          },
        },
      });
    }
  }, [chartData]);

  return (
    <>
      {!isPageOpen ? (
        <div className="data-generator-container">
          <div className="button" id="saveData" onClick={handleButtonClick}>
            Et si on analysait votre année ?
          </div>
        </div>
      ) : (
        <div className="modal-overlay" onClick={() => setIsPageOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-left-border"></div>
            <button className="close-button" onClick={() => setIsPageOpen(false)}>
              X
            </button>

            <div className="modal-header">
              <h2>Analyse de l'année</h2>
            </div>

            <div className="modal-scrollable-content">
              <div className="statistics-container">
                {statistics.map((stat, index) => (
                  <div key={index} className="stat-card">
                    <h3>{stat.key}</h3>
                    <div className="stat-details">
                      <div><span>Moyenne:</span> <span>{stat.avg.toFixed(2)}</span></div>
                      <div><span>Min:</span> <span>{stat.min}</span></div>
                      <div><span>Max:</span> <span>{stat.max}</span></div>
                      <div><span>Médiane:</span> <span>{stat.median}</span></div>
                      <div><span>Écart-type:</span> <span>{stat.stdDev.toFixed(2)}</span></div>
                      <div><span>Évolution totale:</span> <span>{stat.totalEvolution.toFixed(2)}%</span></div>
                      <div><span>Évolution moyenne:</span> <span>{stat.avgEvolution.toFixed(2)}%</span></div>
                    </div>
                  </div>
                ))}
              </div>

              <canvas id="yearAnalyzerChart" width="400" height="200"></canvas>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default YearAnalyzer;
