import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import DeconnectUser from '../../AccountManager/Deconnection/Deconnect';
import { hostLink, isCookieCredentialStillValid } from '../../DatabaseCommunication/GetDataFromUser';

const Header = () => {
    const [sessionValid, setSessionValid] = useState(false);
    const usernameToken = Cookies.get('authPulseUsername');

    useEffect(() => {
        const checkCredentials = async () => {
            const isValid = await isCookieCredentialStillValid();
            setSessionValid(isValid);
            Cookies.set('sessionAccountValid', isValid);
        };

        checkCredentials();
    }, []);

    return (
        <header className="headerPulse">
            <div className="containerPulse">
                <div className="logoPulse">
                    <h1>Pulse Software</h1>
                    {/* <p>Des <strong>logiciels</strong> qui rendent vos <strong>idées réalité</strong>.</p> */}
                </div>
                <nav>
                    <ul>
                        <Link to="/">
                            <li><button>Accueil</button></li>
                        </Link>
                        <Link to="/PulseApps">
                            <li><button>Accéder à la suite Pulse</button></li>
                        </Link>
                        <Link to="/Account">
                            <li>
                                <button className={sessionValid ? 'connected' : ''}>
                                    {sessionValid ? usernameToken : "Connexion"}
                                </button>
                            </li>
                        </Link>
                        {sessionValid ? <DeconnectUser /> : null}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
