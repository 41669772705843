import React, { useEffect, useState } from "react";
import "./profil.css";
import Cookies from "js-cookie";
import SideMenu from "../SideBar/sideBar";
import { GetUserCompanyAccessCode, GetUserDatas } from "../../GatherUserInformationDB/GatherDatabaseUser";
import BetaAccessModal from "../BetaModal/BetaModal";
import PatchnoteModal from "../BetaModal/PatchnoteModal";
import FacebookSDK from "../facebookSDK/fbSdk";
import TrialModal from "../TrialAccess/TrialAccess";
import Popup from "../PopupModule/popUp";
import { hostLink } from "../../DatabaseCommunication/GetDataFromUser";

const AccountPageManager = () => {
  const usernameToken = Cookies.get('authPulseUsername');
  let userData;

  const [isPopupVisible, setPopupVisible] = useState(false);

  const showPopup = () => 
  {
      setPopupVisible(true);
  };

  const hidePopup = () => 
  {
      setPopupVisible(false);
  };

  const createHeader = () => {
    return (
      <div className="title" >
        Bonjour
        <span
          style={{
            background: 'linear-gradient(to bottom, transparent 25%, #FFB703 50%)',
            padding: "2px 4px",
            position: "relative",
            display: "inline-block",
          }}
        >
          {usernameToken}
        </span><br/>
        Nous sommes heureux de vous revoir.

      </div>
    );
  };

  const SaveDataToToken = () =>
    {
      // Save Instagram information
      let dataToSave = {};
  
      dataToSave["authInstaMdp"] = document.getElementById("instagram-password").value;
      dataToSave["authInstaUsername"] = document.getElementById("instagram-username").value;
  
      // Save the object as a JSON string in cookies
      Cookies.set("userNetworkData", JSON.stringify(dataToSave));
    }
  
    const LoadDataFromToken = async () =>
      {
          let dataToSave = {};
          // Retrieve the JSON string from cookies and parse it back into an object
          if (Cookies.get("userNetworkData"))
          {
              dataToSave = JSON.parse(Cookies.get("userNetworkData"));
          }


          
        userData =  await GetUserDatas();
        document.getElementById("compName").textContent = userData[6];
        document.getElementById("userUsername").textContent = userData[1];
        document.getElementById("userId").textContent = userData[2];

        document.getElementById("userCompCode").textContent = await GetUserCompanyAccessCode();
        
      };
      
    useEffect(() => {
      LoadDataFromToken();
    }, []);
  
    const ConnectToInstagram = () =>
    {
      window.location.href = hostLink + '/Facebook/connect';  
      }
    
      const ConnectToYoutube = async() =>
      {
        const userData = await GetUserDatas();

        window.location.href = hostLink + '/authorize?state=' + (JSON.stringify({ email: userData[4] }));
        
      }


  return (
    <div className="main-containerAccManager">


      <SideMenu/>

      <div id="menu-container"></div>
      <div id="actuality-container"></div>

      <div className="allcard" id="globalCard">
        <div className="allcard" id="allcard">
          <div className="cardd" id="generalInformation">
            {createHeader()}
            <img src="https://img.freepik.com/free-vector/peace-mind-concept-illustration_114360-7308.jpg?t=st=1733215674~exp=1733219274~hmac=e2c146414596f876030e32bbf915d9d1cf5d6f3eec5cc36d38ba9f1e6243ccee&w=826" style={{width: "25%"}}/>

          </div>
        </div>

        <div className="allcard general-info-card">
          <div className="horizontal-box">
            <div className="label">Identifiant:</div>
            <div className="value" id="userId"></div>
          </div>
          <div className="horizontal-box">
            <div className="label">Pseudonyme:</div>
            <div className="value" id="userUsername"></div>
          </div>
          <div className="horizontal-box">
            <div className="label">entreprise:</div>
            <div className="value" id="compName"></div>
          </div>
          <div className="horizontal-box">
            <div className="label">code d'accès de l'entreprise:</div>
            <div className="value" id="userCompCode"></div>
          </div>
        </div>


        <div className="allcard networks-info">
  <div className="main-title">information des réseaux sociaux</div>
  <hr className="divider" />
  
  <div className="profile-container"
                onMouseLeave={hidePopup} >

        <div id="connectWithInstagram" className="button" onClick={() => ConnectToInstagram()}>connecter instagram (via facebook)</div>
        <div id="connectWithYoutube" className="button" onClick={() => ConnectToYoutube()}>connecter Youtube</div>

  </div>
  
  <button type="button" id="saveButton" className="save-button" onClick={SaveDataToToken}>
    Save
  </button>

</div>

      </div>

      {/* <FacebookSDK/> */}


    </div>
  );
};

export default AccountPageManager;
