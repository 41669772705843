import React from "react";
import "./ApplicationsPage.css";
import { Link } from "react-router-dom";

// Composant pour chaque application
function ApplicationCard({ appName, description, iconUrl, cardColor }) {
    return (
        <div className="application-card" style={{ backgroundColor: cardColor }}>
            <div className="application-card-content">
                <img src={iconUrl} alt={`${appName} Icon`} className="application-icon" />
                <div className="application-details">
                    <h3 className="application-title">{appName}</h3>
                    <p className="application-description">{description}</p>
                </div>
            </div>
        </div>
    );
}

// Composant principal pour la page des applications
function PulseApps() {
    const applications = [
        {
            id: 1,
            name: "Echowave",
            description: "Outil d'analyse de statistiques de réseaux sociaux",
            iconUrl: "https://via.placeholder.com/100",
            color: "#d1ecf1",
        },
        {
            id: 2,
            name: "EventFlow",
            description: "Bientôt !",
            iconUrl: "https://via.placeholder.com/100",
            color: "#d1ecf1",
        },
        {
            id: 3,
            name: "FlowMate",
            description: "Bientôt !",
            iconUrl: "https://via.placeholder.com/100",
            color: "#d4edda",
        },
    ];

    return (
        <div className="card">
            <h1 className="page-title">Applications Disponibles</h1>
            <div className="applications-grid">
                <Link to="/echowave" className="no-underline">
                <ApplicationCard
                    key={applications[0].id}
                    appName={applications[0].name}
                    description={applications[0].description}
                    iconUrl={applications[0].iconUrl}
                    cardColor={applications[0].color}
                />
                </Link>
            </div>
        </div>
    );
}

export default PulseApps;
